import request from "@/utils/request.ts";

export default {
  page(arg: any) {
    return request({
      url: "/project/projectCar/page",
      method: "get",
      params: arg,
    });
  },
  carType: [
    { label: "载货汽车", value: "1" },
    { label: "轿车", value: "2" },
    { label: "客车", value: "3" },
    { label: "越野汽车", value: "4" },
    { label: "牵引车", value: "5" },
    { label: "专用汽车", value: "6" },
    { label: "自卸汽车", value: "7" },
    { label: "半挂车", value: "8" },
    { label: "其他", value: "9" },
  ],
};
