
import { Vue } from "vue-property-decorator";
import api from "@/api/cararchives";
export default class extends Vue {
  loading = false;
  finished = false;
  refreshing = false;
  carList: any[] = [];
  pageNum = 1;
  pageSize = 5;

  typeFilter(param: string) {
    const obj = api.carType.find((item: any) => item.value === param);
    return obj ? obj.label : "";
  }

  getCarList() {
    setTimeout(() => {
      if (this.refreshing) {
        this.pageNum = 1;
        this.carList = [];
        this.refreshing = false;
      }
      api
        .page({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          projectId: this.$store.state.project.projectinfo.projectId,
        })
        .then((res: any) => {
          if (res.code === 0) {
            this.carList = this.carList.concat(res.data.records);
            const current = res.data.current;
            const pages = res.data.pages;
            if (current >= pages) {
              this.finished = true;
            } else {
              this.pageNum += 1;
            }
          } else {
            this.$toast.fail(res.msg);
          }
        });
    }, 500);
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.getCarList();
  }
}
